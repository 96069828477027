<template>
  <div class='container'>
    <kcHeader></kcHeader>
    <div class="content">
      <div class="kc-aside" >
        <!-- 用户 -->
        <div class="kc-user"   v-if="shopInfo.member_info"  :style="shopInfo.member_info  | getBackgroundSty(time) " >
          <div class="userBox">
            <div class="avatar">
              <img   :src="shopInfo.member_info.avatar" class="avatar-img"/>
              <img v-if="shopInfo.member_info.vip_status == 1"  :src="getShopUserLevelImg(shopInfo.member_info,time)" class="avatar-vip"/>
            </div>
            <div class="username" :style="{color:shopInfo.member_info.vip_level >= 3 ?'#ffffff':''}" >
              <div v-if="shopInfo.member_info.vip_status == 0" >{{shopInfo.member_info.username}}</div>
              <span v-else   :tipsName=" shopInfo.member_info.vip_level | vipLevel " >{{shopInfo.member_info.username}}</span>
            </div>
            <div class="date" :style="{color:shopInfo.member_info.vip_level >= 3 ?'#ffffff':''}">
              {{shopInfo.member_info.vip_status == 0?'开通点亮VIP':time>=shopInfo.member_info.vip_expiry_time?'续费点亮VIP':'有效期：' + shopInfo.member_info.vip_expiry_time}}

            </div>
            <div class="btn" @click="$router.push('/shop')" >{{shopInfo.member_info.vip_status == 0?'开通':'续费'}}</div>
          </div>
          <div class="remain" :style="{color:shopInfo.member_info.vip_level >= 3 ?'#ffffff':''}">
            <p style="margin-bottom:10px;">剩余空间：{{formatSize(shopInfo.member_info.storage_size_over)}}</p>
            <p>剩余流量：{{formatSize(shopInfo.member_info.flow_size_over)}}</p>
          </div>
        </div>
        <div class="record" @click="$router.push('/purchaseRecord')">
            <img src="@assets/img/profile/icon-buy.png"/>
            <span>购买记录</span>
        </div>
        <div class="record" @click="$router.push('/spendRecord')">
            <img src="@assets/img/profile/icon-spend.png"/>
            <span>消费记录</span>
        </div>
      </div>
      <router-view @update="getShopData"></router-view>
    </div>
  </div>
</template>

<script>
import kcHeader from "@components/kcHeader";
import {getGoodsList} from "@assets/api/shop";
import {mapState} from "vuex";
export default {
  name: "",
  components: {
    kcHeader,
  },

  data() {
    return {
      time:'',//当前时间
      token:'',
      shopInfo:{
        member_info:{},
        goods_list:[],
      },
    };
  },

  computed: {
    // ...mapState({shopInfo:'shopInfo'})
  },

  filters:{
    //背景颜色
    getBackgroundSty(val, time){
      console.log('val',val)
      if (null == val || val == '') {
        return false;
      }

      if(val.vip_status == 0){
        return  {
          background:'#F4F6F8',
          boxShadow: '6px 6px 10px 0px rgba(0,116,255,0.1), -6px -6px 10px 0px #FFFFFF, inset -2px -2px 1px 0px rgba(255,255,255,0.5)',

        }
      }else{
        if(time >= val.vip_expiry_time){
          return  {
            background:'#F4F6F8',
            boxShadow: '6px 6px 10px 0px rgba(0,116,255,0.1), -6px -6px 10px 0px #FFFFFF, inset -2px -2px 1px 0px rgba(255,255,255,0.5)',
          }
        }else{
          if(val.vip_level == 1){
            return  {
              background: 'linear-gradient(180deg, #F2FBFF 0%, #E5F6FF 100%)',
              boxShadow:' 6px 6px 10px 0px rgba(0,116,255,0.1), -6px -6px 10px 0px #FFFFFF, inset -2px -2px 1px 0px rgba(255,255,255,0.5)'
            }
          }
          if(val.vip_level == 2){
            return {
              background: 'linear-gradient(180deg, #E8F4FF 0%, #D9E1FF 100%)',
              boxShadow: '6px 6px 10px 0px rgba(0,116,255,0.1), -6px -6px 10px 0px #FFFFFF, inset -2px -2px 1px 0px rgba(255,255,255,0.5)'
            }
          }
          if(val.vip_level >= 3){
            return {
              background: 'linear-gradient(180deg, #6D6D6D 0%, #2A282A 100%)',
              boxShadow: '6px 6px 10px 0px rgba(0,116,255,0.1), -6px -6px 10px 0px #FFFFFF, inset -2px -2px 1px 0px rgba(255,255,255,0.5)'
            }
          }
        }
      }

    },
    //等级文案
    vipLevel(val){
      if(val == 1){
        return '白鸟会员'
      }
      if(val == 2){
        return '蓝鸟会员'
      }
      if(val >= 3){
        return  '黑鸟会员'
      }
    },
  },

  created() {
    this.token = localStorage.getItem('token')
    // console.log('数据数据',this.shopInfo)
    this.getNowDate()
    this.getShopData()
  },

  methods: {
    //获取当前时间
    getNowDate () {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      var weekArr = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天'];
      var week = weekArr[date.getDay()];
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.time = year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
      // return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    //商城信息
    getShopData(){
      getGoodsList().then(res=>{
        if(res.code == 1){
          // this.goodsObj = res.data
          let obj  = res.data
          this.shopInfo = res.data
          console.log('this.shopInfo',this.shopInfo)
          this.$store.commit('getShopInfo',this.shopInfo)
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
  },
};
</script>
<style lang='less' scoped>
@import '@assets/css/profile/shops.less';
</style>